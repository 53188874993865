"use client";

import * as React from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
export function Combobox({
  items,
  onSelect,
  placeholder,
  emptyContent,
  onInput,
  value,
  setValue
}: {
  items: {
    label: string;
    value: string;
  }[];
  onSelect: (value: string) => void;
  onInput: (value: string) => void;
  placeholder: string;
  emptyContent: React.ReactNode;
  value: string;
  setValue: (value: string) => void;
}) {
  const [open, setOpen] = React.useState(false);
  return <Popover open={open} onOpenChange={setOpen} data-sentry-element="Popover" data-sentry-component="Combobox" data-sentry-source-file="combobox.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="combobox.tsx">
        <Button size={"sm"} variant="outline" role="combobox" aria-expanded={open} className="w-[200px] justify-between" data-sentry-element="Button" data-sentry-source-file="combobox.tsx">
          {placeholder}
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" data-sentry-element="CaretSortIcon" data-sentry-source-file="combobox.tsx" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" data-sentry-element="PopoverContent" data-sentry-source-file="combobox.tsx">
        <Command data-sentry-element="Command" data-sentry-source-file="combobox.tsx">
          <CommandInput placeholder={placeholder} className="h-9" onValueChange={value => onInput(value)} value={value} data-sentry-element="CommandInput" data-sentry-source-file="combobox.tsx" />
          <CommandList data-sentry-element="CommandList" data-sentry-source-file="combobox.tsx">
            <CommandEmpty data-sentry-element="CommandEmpty" data-sentry-source-file="combobox.tsx">{emptyContent}</CommandEmpty>
            <CommandGroup data-sentry-element="CommandGroup" data-sentry-source-file="combobox.tsx">
              {items.map(framework => <CommandItem key={framework.value} value={framework.value} onSelect={currentValue => {
              setValue(currentValue === value ? "" : currentValue);
              setOpen(false);
              onSelect(currentValue);
            }}>
                  {framework.label}
                  <CheckIcon className={cn("ml-auto h-4 w-4", value === framework.value ? "opacity-100" : "opacity-0")} />
                </CommandItem>)}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>;
}