import { FrontendCharacter } from "@/pages/app/home/frontendCharacter";
import { Flex, Heading, ScrollArea, Text } from "@radix-ui/themes";
import { CharacterCard } from "@/pages/app/home/characterCard";
import { useAnalytics } from "@/analyticsContext";
import PlaceholderCharacterCard from "./placeholderCharacterCard";
export function CharacterHomeRow({
  title,
  subtitle,
  characters,
  placeholderCount
}: {
  title?: string;
  subtitle?: string;
  characters?: FrontendCharacter[];
  placeholderCount?: number;
}) {
  const analytics = useAnalytics();
  return <>
      <Flex direction={"column"} mb={"2"} data-sentry-element="Flex" data-sentry-source-file="characterHomeRow.tsx">
        {title && <Heading align={"center"}>{title}</Heading>}
        {subtitle && <Text align={"center"} size={"2"}>
            {subtitle}
          </Text>}
      </Flex>
      <Flex className={"flex-row w-full flex-wrap justify-center"} align={"center"} gap={{
      initial: "2",
      lg: "5"
    }} style={{}} data-sentry-element="Flex" data-sentry-source-file="characterHomeRow.tsx">
        {characters ? characters?.map(character => <CharacterCard character={character} key={character.id} onClick={() => {
        analytics.capture("homePage.homeRowCharacterClicked", {
          characterId: character.id,
          characterName: character.name ?? null,
          rowTitle: title,
          rowSubtitle: subtitle
        });
      }} />) : Array(placeholderCount ?? 0).fill(null).map((_, index) => <PlaceholderCharacterCard key={index} />)}
      </Flex>
    </>;
}